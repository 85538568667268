import React from 'react';
import ReactDOM from 'react-dom';
import {  Router, Route, Switch} from "react-router-dom";
import { createBrowserHistory } from "history";
import 'assets/css/main.css';
import App from 'views/App';
import reportWebVitals from './reportWebVitals';

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/" component={App} />
    </Switch>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
