import React, { lazy, Suspense } from 'react';

import MessengerCustomerChat from 'react-messenger-customer-chat';
//Image
import imageLogo from "assets/img/donnisira/DonnisiraLogo.svg";
import ImageSuperimposed from "assets/img/donnisira/minde.svg";
import imageBackground from "assets/img/donnisira/donnisira-into-teamswork.svg";
import { Link } from 'react-router-dom';

const Header = lazy(() => import('components/Header/Header'));
const About = lazy(() => import('components/About/About'));
const AboutEtablissement = lazy(() => import('components/About/AboutEtablissement'));
const AboutEnseignants = lazy(() => import('components/About/AboutEnseignants'));
const AboutEleve = lazy(() => import('components/About/AboutEleve'));
const AboutParent = lazy(() => import('components/About/AboutParent'));
const Features = lazy(() => import('components/Features/Features'));
const Contact = lazy(() => import('components/Contact/Contact'));
const Footer = lazy(() => import('components/Footer/Footer'));

const renderLoader = () => <p>Chargement...</p>;

function App() {
  const homeLink = {
    name: "ACCUEIL",
    id: "home",
    link: "#home",
    active: true
  }
  const FeaturesLink = {
    // name: "LES FONCTIONNALITES",
    name: "FONCTIONNALITES",
    id: "fonctionnalités",
    link: "#fonctionnalités"
  }

  const SolutionsLink = {
    name: "NOTRE SOLUTION",
    id: "solutions",
    link: "#solutions"
  }

  const contactLink = {
    name: "CONTACTEZ-NOUS",
    id: "contact",
    link: "#contact"
  }

  const menuApp= [
    homeLink,
    FeaturesLink,
    SolutionsLink,
    contactLink
  ]
  return (
    <Suspense fallback={renderLoader()}>
       <div >
      <Header
        idLink={homeLink.id}
        menuHeader={menuApp}
        titleHeadLine1="Dɔnnisira - Tous sur le chemin de la Reussite!"
        titleHeadLine2="La solution de gestion d'établissement scolaire."
        imageLogo={imageLogo}
        ImageSuperimposed={ImageSuperimposed}
        imageBackground={imageBackground}
      />

      <Features
        idLink={FeaturesLink.id}
        title="Fonctionnalités"
        dataWowDelay="0.3s"
      />

      <About
        idLink={SolutionsLink.id}
        title="Notre Solution"
        dataWowDelay="0.3s"
      />

      <AboutEtablissement />

      <AboutEnseignants />

      <AboutEleve />

      <AboutParent />


      <Contact idLink={contactLink.id} />

      <Footer
        imageLogo={imageLogo}
      />

      <Link to="#" className="back-to-top">
        <i className="lni-arrow-up"></i>
      </Link>

      <div id="preloader">
        <div className="loader" id="loader-1"></div>
      </div>

      <div>
        <MessengerCustomerChat
          pageId={process.env.REACT_APP_MESSENGER_CUSTOMER_CHAT_PAGE_ID}
          appId={process.env.REACT_APP_MESSENGER_CUSTOMER_CHAT_APP_ID}
          themeColor="#b52e31"
          loggedInGreeting="Bonjour, comment pouvons-nous vous aider ?"
          loggedOutGreeting="Merci pour votre visite."
          xfbml={true}
          version="10.0"
          language="fr_FR"
        />
      </div>
    </div>
    </Suspense>
   );
}

export default App;
